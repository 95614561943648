import Axios from 'axios'
import { jwtDecode } from 'jwt-decode'

const axios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true
})

axios.interceptors.request.use(
    async(config) =>{
        let token = localStorage.getItem('access')
        if (token) {
            const decodedToken = jwtDecode(token)
            const currentTime = Math.floor(Date.now()/1000)
            const tiempoRestante = decodedToken.exp - currentTime
            console.log(tiempoRestante)

            if(tiempoRestante <60 && !!localStorage.getItem('refresh') && !config.url.includes("refresh")){
                let result = await refreshAccessToken()
                if(result && tiempoRestante <1){
                    token = result
                }
            }
            config.headers.Authorization = `Bearer ${token}`
            console.log(config)
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

async function refreshAccessToken(){
    try{
        const refresh = localStorage.getItem("refresh")
        if(refresh == null) return
        const response = await axios.post("/api/token/refresh/",{refresh})
        const result = response.data
        localStorage.setItem("access", result.access)
        if(result.refresh){
            localStorage.setItem("refresh", result.refresh)
        }else{
            localStorage.removeItem("refresh")
        }
        return result.access
    }catch (error){
        return null
    }
}


export default axios
