import PrivateRoute from "../components/layouts/PrivateRoute";
import DashboardView from "../pages/DashboardView";
import LoginView from "../pages/LoginView";
import {createBrowserRouter} from 'react-router-dom'
import NotFoundView from "../pages/NotFoundView";
import AppLayout from "../components/layouts/AppLayout";
import GoogleLoginCallbackView from "../pages/GoogleLoginCallbackView";

function ErrorElement(){
    return <AppLayout>
        Hubo un error
    </AppLayout>
}
const rutas = [
    {
        path:"/login",
        element:<LoginView/>
    },
    {
        path:"/login/google/callback",
        element:<GoogleLoginCallbackView/>
    },
    {
        path:"/",
        element: <PrivateRoute Componente={DashboardView}/>
    },
    {
        path:"*",
        element: <NotFoundView/>
    }
]

const router = createBrowserRouter(rutas.map(item=>{
    item.errorElement = <ErrorElement/>
    return item
}),  {
    future: {
      v7_relativeSplatPath: true,
      v7_partialHydration: true,
      v7_normalizeFormMethod: true,
      v7_fetcherPersist: true,
      v7_skipActionErrorRevalidation: true,
      v7_startTransition: true  
    },
  })
export default router;