import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from './Footer'
import PropTypes from 'prop-types'

export default function AppLayout({title,children}) {
  return <div className="wrapper">
  <Navbar/>
  <Sidebar/>
  
  <div className="content-wrapper">

    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
         {title &&  <div className="col-sm-6">
            <h1 className="m-0">{title}</h1>
          </div>}
         
        </div>
      </div>
    </div>
    <div className="content">
      <div className="container-fluid">
        
        {children}
      </div>

    </div>
  </div>
 
 
  <Footer/>
</div>

}

AppLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
}