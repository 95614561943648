import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../../context/AuthContext'
import {Navigate} from 'react-router-dom'
import { LOGIN_URL } from '../../utils/constants'
import LoadingView from './Loading'

export default function PrivateRoute({Componente, ...props}) {
    const { currentUser, cargandoDatosUsuario} = useAuth()

    if(cargandoDatosUsuario){
        return <LoadingView/>
    }
    else if(currentUser){
        return <Componente {...props}/>
    }else{
        return <Navigate to={LOGIN_URL} />
    }
}

PrivateRoute.propTypes = {
    Componente: PropTypes.any
}
