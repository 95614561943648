import AppLayout from "./AppLayout";
import ClipLoader from "react-spinners/ClipLoader";

export function Loading() {
    return <div style={{ margin: "0 auto", width: "max-content" }}>
        <ClipLoader />
    </div>

}


export default function LoadingView() {
    return <AppLayout>
        <Loading />

    </AppLayout>
}