import { createContext, useContext, useEffect, useState } from "react";
import authService from "../services/authService";

const AuthContext = createContext()
function AuthProvider({ children }) {
    const [estaAutenticado, setEstaAutenticado] = useState(!!localStorage.getItem('access'))
    const [currentUser, setCurrentUser] = useState()
    const [cargandoDatosUsuario, setCargandoDatosUsuario] = useState(!!localStorage.getItem('access'))


    useEffect(() => {
        async function loadProfile() {
            setCargandoDatosUsuario(true)
            const result = await authService.profile()
            if (result) {
                setCurrentUser(result)
            } else {
                setEstaAutenticado(false)
            }
            setCargandoDatosUsuario(false)
        }
        if (estaAutenticado && !currentUser) {
            loadProfile()
        }

    }, [estaAutenticado])

    const valores = { estaAutenticado,setEstaAutenticado, currentUser, setCurrentUser, cargandoDatosUsuario }
    return <AuthContext.Provider value={valores}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)
export { useAuth }
export default AuthProvider