import React from 'react';
import styled from 'styled-components';
import AppLayout from '../components/layouts/AppLayout';

const NotFoundView = () => {
    return <AppLayout>
        <Container>
            <Title>404</Title>
            <Subtitle>Página no encontrada</Subtitle>
            <Message>Lo sentimos, la página que estás buscando no existe.</Message>
        </Container>

    </AppLayout>

};

export default NotFoundView;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  color: #343a40;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 6rem;
  margin: 0;
  color: #dc3545;
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  margin: 0;
`;

const Message = styled.p`
  font-size: 1.2rem;
  margin-top: 1rem;
`;
