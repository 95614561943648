import axios from "../utils/axios"
import { LOGIN_URL } from "../utils/constants"

const authService = {
    authenticate: async (setErrors, email,password)=>{
        setErrors({})
        try{
            const result = await axios.post("/api/token/",{email,password})
            return result.data
        }catch (e){
            if(e?.response?.data?.errors){
                setErrors(e.response.data.errors)
                
            }else if (e?.response?.data?.detail){
                setErrors(e?.response?.data)
            }
            return null
        }
    },
    authenticateGoogle: async(code)=>{
        try {
            const response = await axios.post("/authentication/social_auth/login/google/callback",{code})
            return response.data
        } catch (error) {
            console.log(error)
            return null
        }
    }
    ,
    profile: async ()=>{
        try{
            const result = await axios.get("/authentication/profile")
            return result.data
        }catch{
            return null
        }
    },

    logout: async ()=>{
        localStorage.removeItem("access")
        localStorage.removeItem("refresh")
        window.location.href = LOGIN_URL
    }

}

export default authService