import './App.css';
import { RouterProvider } from 'react-router-dom'
import router from './utils/routes';
import AuthProvider from './context/AuthContext';
import { Toaster } from 'sonner'


function App() {
  return <AuthProvider>
    <RouterProvider router={router}>
    </RouterProvider>
    <Toaster richColors />
  </AuthProvider>
}

export default App;
