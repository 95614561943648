import React, { useState } from 'react'
import AppLayout from '../components/layouts/AppLayout'
import { FormPanel, FormPanelHeader, Input, InputError } from '../components/forms/FormLayout'
import { APP_NAME, LOGIN_REDIRECT_URL } from '../utils/constants'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import authService from '../services/authService'
import { toast } from 'sonner'
import { useAuth } from '../context/AuthContext'

export default function LoginView() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState({})
    const {estaAutenticado,setEstaAutenticado} = useAuth()

    const navigate = useNavigate()


    async function handleSubmit(ev) {
        ev.preventDefault()
        const result = await authService.authenticate(setErrors,email,password)
        if(result){
            const {access, refresh} = result
            localStorage.setItem("access", access)
            localStorage.setItem("refresh", refresh)
            // const profile = await authService.profile()
            // setCurrentUser(profile)
            setEstaAutenticado(true)
            setTimeout(() => {
                navigate(LOGIN_REDIRECT_URL)
            }, 1);
        }else{
            toast.error("Algunos datos no son válidos")
        }

    }
    return estaAutenticado? <Navigate to="/" />:<AppLayout>
        <div className="login-box" style={{ margin: "0 auto" }}>
            <FormPanel>
                <FormPanelHeader>
                    <span className="h1"><b>{APP_NAME}</b></span>
                </FormPanelHeader>
                <div className="card-body">
                    <p className="login-box-msg">Iniciar sesión</p>
                    <form onSubmit={handleSubmit}>

                        <Input
                            type="email" placeholder="Correo electrónico" autoComplete="off"
                            value={email} onChange={(ev) => setEmail(ev.target.value)}
                        />
                        <InputError messages={errors.email} />


                        <Input
                            type="password" autoComplete="new-password"
                            value={password} onChange={ev => setPassword(ev.target.value)}
                        />
                        <InputError messages={errors.password} />
                        <div style={{ textAlign: "end" }}>
                            <button type="submit" className="btn btn-primary">Iniciar sesión</button>
                        </div>
                        <InputError messages={errors.detail} />
                    </form>
                    <div className="social-auth-links text-center mt-2 mb-3">
                        {/* <Link to="#" className="btn btn-block btn-primary">
                            <i className="fab fa-facebook mr-2" /> Iniciar sesión con Facebook
                        </Link> */}
                        <Link to={`${process.env.REACT_APP_BACKEND_URL}/authentication/social_auth/login/google`} className="btn btn-block btn-danger">
                            <i className="fab fa-google-plus mr-2" /> Iniciar sesión con Google+
                        </Link>
                    </div>
                    {/* /.social-auth-links */}
                    <p className="mb-1">
                        <a href="forgot-password.html">He olvidado mi contraseña</a>
                    </p>
                    <p className="mb-0">
                        <a href="register.html" className="text-center">Registrar una nueva cuenta</a>
                    </p>
                </div>
                {/* /.card-body */}
            </FormPanel>
            {/* /.card */}
        </div>

    </AppLayout>
}
