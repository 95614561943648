function FormPanel({children}){
    return <div className="card card-outline card-primary">
        {children}
    </div>
}

function FormPanelHeader({children}){
    return <div className="card-header text-center">
    {children}
  </div>
}

function InputIcon({inputType}){
    let iconClassName;
    switch(inputType){
        case "email": iconClassName = "fas fa-envelope" ;break;
        case "password": iconClassName ="fas fa-lock"
    }
    return <div className="input-group-append">
    <div className="input-group-text">
        <span className={iconClassName} />
    </div>
</div>
}

function Input({type,addIcon=true, ...props}){
    return <div className="input-group mb-3">
    <input type={type} className="form-control" {...props} />
    {addIcon && <InputIcon inputType={type}></InputIcon>}
</div>
}

function InputError({messages, styles = {}}){
    if(!messages) return
    if (!Array.isArray(messages)) messages = [messages]
    return <div className="error-messages">
        {messages.map(message=>{
            return <span key={message} style={{display:"block", ...styles}}>{message}</span>
        })}
    </div>
}

export {
    FormPanel, FormPanelHeader, Input, InputError
}