import React, { useEffect } from 'react'
import AppLayout from '../components/layouts/AppLayout'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Loading } from '../components/layouts/Loading'
import authService from '../services/authService'
import { LOGIN_REDIRECT_URL, LOGIN_URL } from '../utils/constants'
import Swal from 'sweetalert2'
import { useAuth } from '../context/AuthContext'

export default function GoogleLoginCallbackView() {
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const code = urlParams.get("code")
    const navigate = useNavigate()
    const { setEstaAutenticado } = useAuth()

    useEffect(() => {
        async function login() {
            const result = await authService.authenticateGoogle(code)
            if (result) {
                const { access, refresh } = result
                localStorage.setItem("access", access)
                localStorage.setItem("refresh", refresh)
                setEstaAutenticado(true)
                setTimeout(() => {
                    navigate(LOGIN_REDIRECT_URL)
                }, 1);
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Hubo un error al iniciar sesión",
                    icon: "error"
                }).then(() => navigate(LOGIN_URL))
            }
        }
        login()
    }, [code])
    return code ? <AppLayout>
        <Loading />
    </AppLayout> : <Navigate to="/404" />
}
